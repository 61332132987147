<template>
  <div class="activities-agenda" v-if="canView">
    <page-header
      :title="title"
      icon="fa fa-calendar-day"
      :links="getLinks()"
    >
    </page-header>
    <b-row>
      <b-col>
        <div class="info-message">
          Le planning des activités donne une vision globale des activités dans l'année.
          Pour le planning d'une semaine donnée:
          <router-link :to="{ name: 'agenda-activity-dates', }">
            Voir l'occupation des lieux
          </router-link>
        </div>
      </b-col>
    </b-row>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)">
      <b-row>
        <b-col cols="10">
          <check-box-select
            :choices="listOfPlaces"
            inline=""
            :initial-value="selectedPlaces"
            @changed="onPlacesChanged"
            :style-callback="getChoiceStyle"
            small
          ></check-box-select>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-form-group label="Année scolaire" label-for="activity">
            <b-form-select
              id="activity"
              v-model="selectedSchoolYear"
              :disabled="isLoading(loadingName) || (schoolYears.length <= 1)"
            >
              <b-select-option :value="elt" v-for="elt in schoolYears" :key="elt.id">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
          <div v-if="disabledActivitiesCount" style="background: #eee; margin-bottom: 1px">
            <b-form-checkbox class="small" v-model="allowDisabled">
              <counter-label
                :counter="disabledActivitiesCount"
                label="activité suspendue"
                label-n="activités suspendues"
              >
              </counter-label>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <div ref="printMe">
        <div class="text-right hide-here" v-if="selectedSchoolYear">
          {{ selectedSchoolYear.name }}
        </div>
        <div class="hide-here" :class="showTable ? 'no-print' : ''">
          <div
            style="display: inline-block"
            v-for="place in selectedPlaces"
            :key="place.id"
            :style="getChoiceStyle(place, true)"
          >
            {{ place.name }}
          </div>
        </div>
        <weekly-calendar
          :week-date="theDate"
          :events="events"
          :force-width="forceWidth"
          :can-add-comments="false"
          :can-edit-comments="false"
          :can-view-comments="false"
          :show-comments="false"
          :allow-hide-sunday="!hasSundays"
          :hide-dates="true"
          :clickable="true"
          @clicked="onClickEvent"
          v-if="!showTable"
        ></weekly-calendar>
        <div v-if="showTable" ref="excelMe" class="small-text">
          <x-table
            id="activities"
            :columns="columns"
            :items="items"
            verbose-name="activité"
          ></x-table>
        </div>
      </div>
    </div>
    <view-activity-modal :activity="selectedActivity"></view-activity-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import WeeklyCalendar from '@/components/Controls/Calendar/WeeklyCalendar'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader'
import ViewActivityModal from '@/components/Activities/ViewActivityModal.vue'
import { dateToString } from '@/filters/texts'
import { ActivitiesMixin } from '@/mixins/activities'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeActivity } from '@/types/activities'
import { makePlace } from '@/types/base'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { distinct, existsIn } from '@/utils/arrays'
import { compareStrings } from '@/utils/sorting'
import { getColor } from '@/utils/colors'
import { slugify } from '@/utils/strings'
import { displayHourMin } from '@/utils/time'

export default {
  name: 'activities-agenda',
  props: {
  },
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    XTable,
    ViewActivityModal,
    CounterLabel,
    WeeklyCalendar,
    CheckBoxSelect,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'activitiesAgenda',
      activities: [],
      selectedPlaces: [],
      busyEvents: [],
      allPlaces: [],
      thePlaces: [],
      theDateAsString: '',
      validDateAsString: '',
      forceWidth: 0,
      selectedDatetime: null,
      editEvent: null,
      showAllPlaces: true,
      selectedSchoolYear: null,
      schoolYears: [],
      planning: [],
      hasSundays: false,
      allowDisabled: false,
      disabledActivitiesCount: 0,
      selectedActivity: null,
      showTable: false,
    }
  },
  async created() {
    if (this.$route.query && this.$route.places) {
      this.placeIds = this.$route.query.places.split('-').map(elt => +elt)
    }
    await this.loadSchoolYears()
    await this.loadData()
  },
  watch: {
    selectedPlaces: function() {
      this.updateRoute()
      this.buildPlanning()
    },
    selectedSchoolYear() {
      this.buildPlanning()
    },
    allowDisabled() {
      this.buildPlanning()
    },
  },
  computed: {
    title() {
      return 'Planning ' + this.activitiesTitle
    },
    listOfPlaces() {
      return this.showAllPlaces ? this.allPlaces : this.places
    },
    theDate() {
      return moment().toDate()
    },
    canView() {
      return this.hasAllPerms(['activities.view_coreactivity', 'home.view_place'])
    },
    places() {
      return distinct(this.activities.filter(elt => elt.place && elt.place.id).map(elt => elt.place)).sort(
        (place1, place2) => compareStrings(place1.name, place2.name)
      )
    },
    placeIds() {
      if (this.selectedPlaces.length !== this.places.length) {
        return this.selectedPlaces.map(elt => elt.id)
      } else {
        return []
      }
    },
    selectedPlacesIds() {
      return this.selectedPlaces.map(elt => elt.id)
    },
    selectedActivities() {
      const selectedPlaces = this.selectedPlaces.map(elt => elt.id)
      return this.activities.filter(
        activity => (this.selectedSchoolYear.id === activity.schoolYear.id)
      ).filter(
        event => existsIn([event.place.id], selectedPlaces)
      ).filter(
        activity => (!activity.disabled || this.allowDisabled)
      )
    },
    events() {
      const events = []
      for (const elt of this.planning) {
        const color = this.getColor(elt.place)
        const event = {
          id: elt.id,
          activity: elt.activity,
          title: elt.title,
          start: elt.start,
          end: elt.end,
          color: color.text,
          backgroundColor: color.background,
          raw: elt.raw,
          siblings: this.getSiblings(elt),
          absence: false,
          opacity: 1,
          comments: '',
        }
        events.push(event)
      }
      return events
    },
    columns() {
      let columns = [
        {
          name: 'label',
          label: 'Activité',
          maxWidth: '200px',
          onClick: item => {
            this.onShowActivity(item.activity)
          },
          isLink: () => {
            return true
          },
          linkUrl: item => {
            return router.resolve(
              {
                name: 'activity-detail',
                params: { activityId: item.activity.id, },
              }
            ).href
          },
        },
        {
          name: 'place',
          label: 'Lieu',
          maxWidth: '200px',
        },
        {
          name: 'managedBy',
          label: 'Animateur',
          maxWidth: '200px',
          contentCallback: (col, item) => {
            return item.activity.managedBy.map(elt => elt.individual.firstAndLastName()).join(' <br />')
          },
        },
        {
          name: 'day',
          label: 'Jour',
        },
        {
          name: 'startTime',
          label: 'Début',
        },
        {
          name: 'endTime',
          label: 'Fin',
        },
        {
          name: 'startsOn',
          label: 'Début le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'priceName',
          label: 'Prix',
        }
      ]
      return columns
    },
    items() {
      return this.selectedActivities.map(elt => this.makeActivityItem(elt))
    },
  },
  methods: {
    dateToString,
    displayHourMin,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        if (this.schoolYears.length > 0) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    buildPlanning() {
      const planning = []
      this.hasSundays = false
      this.disabledActivitiesCount = 0
      if (this.selectedSchoolYear) {
        let id = 0
        const today = moment()
        const weekDay = today.weekday()
        const monday = today.add(-weekDay, 'days')
        const mondayStr = monday.format('YYYY-MM-DD')
        let activities = this.activities.filter(
          activity => (
            activity.place && activity.place.id &&
            existsIn([activity.place.id], this.selectedPlacesIds) &&
            this.selectedSchoolYear.id === activity.schoolYear.id
          )
        )
        this.disabledActivitiesCount = activities.filter(act => act.disabled).length
        activities = activities.filter(
          activity => (!activity.disabled || this.allowDisabled)
        )
        for (const activity of activities) {
          for (const elt of activity.getPlanning()) {
            const theDay = moment(mondayStr).add(elt.dayNum, 'days').format('YYYY-MM-DD')
            const start = new Date(theDay + 'T' + elt.startTime)
            const end = new Date(theDay + 'T' + elt.endTime)
            if ((elt.dayNum === 6) && !this.hasSundays) {
              // affichage colonne dimanche
              this.hasSundays = true
            }
            const event = {
              id: ++id,
              title: activity.name,
              start: start,
              end: end,
              place: activity.place,
              raw: elt,
              activity: activity,
            }
            planning.push(event)
          }
        }
      }
      this.planning = planning
    },
    updateRoute() {
      const query = {
      }
      if (this.category) {
        query.category = this.category
      }
      if (this.placeIds.length) {
        query.places = this.placeIds.map(elt => '' + elt).join('-')
      }
      router.push({ path: this.$route.path, query: query, })
    },
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadAllPlaces()
      await this.loadActivities()
      this.endLoading(this.loadingName)
    },
    async loadActivities() {
      try {
        const category = this.category
        let url = '/api/activities/activities/agenda/?all=1'
        if (category) {
          url += '&category=' + category
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activities = resp.data.map(makeActivity)
        if (this.placeIds.length) {
          this.selectedPlaces = this.listOfPlaces.filter(
            elt => existsIn([elt.id], this.placeIds)
          )
        } else {
          if (this.places.length) {
            this.selectedPlaces = this.places
            // this.showAllPlaces = false
          } else {
            this.selectedPlaces = []
            // this.showAllPlaces = true
          }
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadAllPlaces() {
      try {
        let url = '/api/home/places/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.allPlaces = resp.data.map(elt => makePlace(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (this.showTable) {
        links.push(
          {
            id: 2,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 3,
            label: 'Planning',
            callback: () => { this.showTable = false },
            icon: 'fa fa-calendar-day',
            cssClass: 'btn-secondary',
          }
        )
      } else {
        links.push(
          {
            id: 3,
            label: 'Tableau',
            callback: () => { this.showTable = true },
            icon: 'fas fa-table',
            cssClass: 'btn-secondary',
          }
        )
      }
      return links
    },
    getChoiceStyle(place, pdf = false) {
      const color = this.getColor(place)
      return {
        color: color.text,
        backgroundColor: color.background,
        padding: '0px 2px',
        fontSize: pdf ? '11px' : '16px',
        margin: '1px',
      }
    },
    getSiblings(event) {
      let busyEvents = []
      let index = 0
      for (const place of this.selectedPlaces) {
        const placeDates = this.planning.filter(elt => elt.place.id === place.id)
        for (const placeDate of placeDates) {
          if (placeDate.id === event.id) {
            index = busyEvents.length
            busyEvents.push(placeDate)
          } else if (placeDate.raw.match(event.raw)) {
            busyEvents.push(placeDate)
          }
        }
      }
      return {
        index: index,
        count: busyEvents.length,
      }
    },
    getColor(place) {
      if (place.backgroundColor) {
        return { background: place.backgroundColor, front: place.textColor, }
      } else {
        const placeIndex = this.listOfPlaces.map(elt => elt.id).indexOf(place.id)
        return getColor(placeIndex, true)
      }
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1&colors=1'
      const docSlug = slugify('planning-activites-' + this.selectedSchoolYear.name.replace('/', '-'))
      this.forceWidth = 1020
      const that = this
      setTimeout(
        async function() {
          const docContent = that.$refs.printMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
            that.forceWidth = 0
          } catch (err) {
            await that.addError(that.getErrorText(err))
          }
        },
        100
      )
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify('planning-activites-' + this.selectedSchoolYear.name.replace('/', '-'))
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onPlacesChanged(event) {
      this.selectedPlaces = event.choices
    },
    onClickEvent(event) {
      this.onShowActivity(event.event.activity)
    },
    onShowActivity(activity) {
      this.selectedActivity = activity
      this.$bvModal.show('bv-modal-view-activity')
    },
    makeActivityItem(activity) {
      return {
        id: activity.id,
        activity: activity,
        label: activity.name,
        place: activity.place.name,
        managedBy: '-',
        day: activity.day,
        startTime: displayHourMin(activity.startTime),
        endTime: displayHourMin(activity.endTime),
        priceName: activity.getPriceName(),
        startsOn: activity.startDate,
      }
    },
  },
}
</script>

<style scoped lang="less">
.info-message {
  padding: 2px 10px;
  background: #eee;
  font-size: 0.8em;
  margin-bottom: 2px;
}
</style>
